import { ErrorHandler, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { DatePipe, ViewportScroller } from '@angular/common';
import { ActivatedRoute, NavigationEnd, Router, Scroll } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule, BrowserTransferStateModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NgxEchartsModule } from 'ngx-echarts';
import { QuillModule } from 'ngx-quill';
import { config } from '@fortawesome/fontawesome-svg-core';
import { TooltipModule, TooltipOptions } from 'ng2-tooltip-directive';
import { GraphQLModule } from './graphql.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CustomizeQuillService, QUILL_MODULES } from './modules/shared/services/customize-quill.service';

config.autoAddCss = false; // We are importing the css in styles.scss
export const MyDefaultTooltipOptions: TooltipOptions = {
  'hide-delay': 0
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserTransferStateModule,
    HttpClientModule,
    ReactiveFormsModule,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts')
    }),
    QuillModule.forRoot({ modules: QUILL_MODULES }),
    TooltipModule.forRoot(MyDefaultTooltipOptions as TooltipOptions),
    GraphQLModule,
    AppRoutingModule,
    BrowserAnimationsModule
  ],
  providers: [DatePipe],
  exports: [],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(
    private router: Router,
    private title: Title,
    private viewportScroller: ViewportScroller,
    private customizeQuillService: CustomizeQuillService
  ) {
    customizeQuillService.setupQuillCustomizations();

    router.events.pipe().subscribe((e) => {
      if (e instanceof Scroll) {
        this.scrollPage(e);
      } else if (e instanceof NavigationEnd) {
        this.setPageTitle();
      }
    });
  }

  getActivatedRoute(): ActivatedRoute {
    let currentRoute = this.router.routerState.root;
    while (currentRoute.firstChild) {
      currentRoute = currentRoute.firstChild;
    }
    return currentRoute;
  }

  scrollPage(scroll: Scroll) {
    if (scroll.position) {
      // backward navigation
      this.viewportScroller.scrollToPosition(scroll.position);
    } else if (scroll.anchor) {
      // anchor navigation
      if (document.getElementById(scroll.anchor)) {
        this.viewportScroller.scrollToAnchor(scroll.anchor);
      } else {
        setTimeout(() => this.viewportScroller.scrollToAnchor(scroll.anchor));
      }
    } else {
      // forward navigation
      const activateRoute = this.getActivatedRoute();
      if (!activateRoute.snapshot.data.preventScroll) {
        this.viewportScroller.scrollToPosition([0, 0]);
      }
    }
  }

  setPageTitle() {
    const activateRoute = this.getActivatedRoute();
    if (activateRoute.snapshot.data.title) {
      this.title.setTitle(activateRoute.snapshot.data.title);
    }
  }
}
